import MetaMaskOnboarding from '@metamask/onboarding';
import React from 'react';
import Web3 from 'web3';
import numeral from 'numeral';

import { BigNumber } from '@ethersproject/bignumber';

import { chainMap, enforceChain } from './tools/ChainTools.js'
import { useInterval } from './tools/UseInterval.js'

import { contractConfigs } from './deliJS/farmgod-core.js'
import { Token } from './tools/token.js'

import {formatTokenBalance} from './tools/tokenUtilities'


import { Wallet } from './deliJS/wallet.js'

 import {Footer } from './deliJS/footer.js'

import bg from './gdeli/bg.png'

export function Dapp() {
  // state for managing whether a transaction is pending
  const [isPending, setIsPending] = React.useState(false);

  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  // Connecting to Metamask
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----
  const [connected, setConnected] = React.useState(false)
  const [accounts, setAccounts] = React.useState([]);
  const [mmBtnText, setMMBtnText] = React.useState("Connect");


  // attached to the accountsChanged event listener
  // triggered once manually via connectMM
  function handleNewAccounts(newAccounts) {
    setAccounts(newAccounts);
  }

  // attached to the chainChanged event listener
  // triggered once manually via main hook
  // calls letItRip if the proper chain is selected
  function handleChainChange(chainId) {
    setMMBtnText("Connected to " + chainMap(window.ethereum.chainId));
     enforceChain("Fantom", letItRip)
  }

  // when triggered, connectMM requests the user connects to the dApp
  // if the user is already connected, or after the user connects,
  // connectMM sets the accounts state to the user's connected accounts,
  // and sets the connected state to true
  const connectMM = () => {
      if (MetaMaskOnboarding.isMetaMaskInstalled()) {
        window.ethereum
          .request({ method: 'eth_requestAccounts' })
          .then((newAccounts) => {
            handleNewAccounts(newAccounts)
            setConnected(true)
            setShowMinters(true)
          })
      } 
  }

  // once the user is connected, add the accountsChanged event listener
  React.useEffect(() => {
    if (connected) {
      window.ethereum.on('accountsChanged', handleNewAccounts);
      return () => {
        window.ethereum.on('accountsChanged', handleNewAccounts);
      };
    }
  }, [connected]);


  // once the user is connected, add the chainChanged event listener
  React.useEffect(() => {
    if (connected) {
      console.log(window.ethereum.chainId)
      window.ethereum.on('chainChanged', handleChainChange);
      return () => {
        window.ethereum.on('chainChanged', handleChainChange);
      }
    }
  }, [connected])
  
  // --------- -------------------------------------------------------------------------------
  // MAIN HOOK -------------------------------------------------------------------------------
  // --------- -------------------------------------------------------------------------------

  // if a user is connected with at least one account,
  // trigger the handleChainChange function
  React.useEffect( () => {
    if (connected) {
        if (accounts.length > 0) {
          handleChainChange(window.ethereum.chainId)  
        }
      }
  }, [connected])


  // --------- -------------------------------------------------------------------------------

  // -- end of connecting to metamask
  // --------- -----------%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%-----

  const [isDisabled, setDisabled] = React.useState(false);



  var web3 = new Web3(Web3.givenProvider || 'http://localhost:8545');

var farms = new web3.eth.Contract(contractConfigs["farm"]["abi"], contractConfigs["farm"]["address"])

const Farm = (props) => (<div className="farm" tid={props.tid}><img src={props.img} /><div><HarvestButton fid={props.tid}/><WaterButton fid={props.tid}/></div></div>)

const letItRip = () => {
  getUserBalance()
  getAcreBalance()
  getWaterBalance()
  getBushelBal()
  getGseedBal()
  getCaneBal()
  getAllGlobalEmissions()
  getTotalSupply()
}
const tokenId = React.useRef();
const [userBal, setUserBal] = React.useState(0)


const getUserBalance = () => {
  farms.methods.balanceOf(window.ethereum.selectedAddress).call({from: window.ethereum.selectedAddress})
    .then((res)=> {
      setUserBal(res)
      console.log(res)
    })
}
const [activeIndex, setActiveIndex] = React.useState(0)
const prev = () => {
  
  if (activeIndex > 0) {
    setActiveIndex(activeIndex - 1)
  }
  
}
const next = () => {
  console.log("previous",next)
  if (activeIndex < userBal - 1) {
    setActiveIndex(activeIndex + 1)
  }
  
}

const [activeFarm, setActiveFarm] = React.useState(0);
React.useEffect(()=>{
  getFarmId()
}, [activeIndex])
const getFarmId = () => {
  if (window.ethereum.selectedAddress == null) {

  } else {
    farms.methods.tokenOfOwnerByIndex(window.ethereum.selectedAddress, activeIndex).call({from: window.ethereum.selectedAddress})
    .then((res) => {
      setActiveFarm(res)
    })
}
  }
  

const [activeImg, setActiveImg] = React.useState("");

React.useEffect(()=>{
  getFarmImg()
  getFarmType()
},[activeFarm])
const [farmType, setFarmType] = React.useState()
const getFarmType = () => {
  console.log("getting type for: ",activeFarm)
 farms.methods.getFarmType(activeFarm).call(
        {from: window.ethereum.selectedAddress})
      .then(
        (res) => {
      
          let f = 0;
          
          if (res == "Wheat Farm") {
            f = 0
          } else if (res == "Sugarcane Plantation") {
            f = 1
          } else {
            f = 2
          }
          setFarmType(f)  
        })
  
}
const getFarmImg = () => {
  if (window.ethereum.selectedAddress == null) {

  } else {
    console.log("getting img for: ",activeFarm)
 farms.methods.tokenURI(activeFarm).call(
        {from: window.ethereum.selectedAddress})
      .then(
        (res) => {
          console.log(res)
          let x = atob(res.replace(/^data:application\/(json);base64,/, ''));
          x = JSON.parse(x)
          console.log(x)
          x = x.image
          console.log(x)
          //x = atob(x.replace(/^data:image\/svg\+xml;base64,/, ''))
          //console.log(x)
          setActiveImg(x)  
        })
  }
}


const waterFarm = (id) => {
  return () => {
    console.log("id",id)
    let fid = id
    approveIfNeeded("water",
      (res) => {
        setIsPending(true)
        setPendingText("Watering Farm")
        farms.methods.waterFarm(fid)
          .send({from: window.ethereum.selectedAddress})
          .then((res) => {
            setIsPending(false)
            getFarmImg()
          })
      }
      )
  }
}



const harvest = (id) => {
  return () => {


  
  console.log(id)
  if (farmType == 0) {
    setIsPending(true)
    setPendingText("Harvesting Bushels")
    farms.methods.harvestBushels(id)
    .send({from: window.ethereum.selectedAddress})
      .then((res) => {
        getBushelBal()
        setIsPending(false)
        getFarmImg()
      })
    } else if (farmType ==1) {
      setIsPending(true)
    setPendingText("Harvesting Cane")
      farms.methods.harvestCane(id)
    .send({from: window.ethereum.selectedAddress})
      .then((res) => {
    
        getCaneBal()
        setIsPending(false)
        getFarmImg()
      })
    } else {
      setIsPending(true)
    setPendingText("Harvesting gSeed")
      farms.methods.harvestGseed(id)
    .send({from: window.ethereum.selectedAddress})
      .then((res) => {
       
        getGseedBal()
        setIsPending(false)
        getFarmImg()
      })
    }
  }
  
}

const HarvestButton = (props) => <button onClick={harvest(props.fid)}>Harvest</button>
const WaterButton = (props) => <button onClick={waterFarm(props.fid)}>Water Farm</button>

const approveIfNeeded = (tokenName, cb = () => {}) => {
  var user = window.ethereum.selectedAddress
  var token = contractConfigs[tokenName]["address"]
  var spender = contractConfigs["farm"]["address"]

  var tokenContract = new web3.eth.Contract(contractConfigs[tokenName]["abi"], token)

  tokenContract.methods.allowance(user, spender)
    .call({from: user})
    .then((res) => {
      
      console.log(res)
      console.log(typeof res.toString())
      if (res.toString().length >= 20) {
        cb()
      } else {
        setIsPending(true)
        setPendingText("Approving Farm Contract to Spend 100,000 "+tokenName)
        tokenContract.methods.approve(spender, "100000"+ "0".repeat(18))
          .send({from: user})
          .then(cb)
      }
      
    })

}

const approveThenMintWheatFarm = () => {
  approveIfNeeded("acre",
    (res) => {
      approveIfNeeded("water",
        (res) => {
          setIsPending(true)
          setPendingText("Minting a Wheat Farm")
          farms.methods.createWheatFarm()
            .send({from: window.ethereum.selectedAddress})
            .then((res) => {
              getUserBalance()
              getAcreBalance()
              getWaterBalance()
              getAllGlobalEmissions()
              setViewFarms(false)
              setTimeout(()=>{setViewFarms(true)}, 400)
              setIsPending(false)
            }
              
              )
        }
        )
    }
    )
}

const approveThenMintPlantation = () => {
  approveIfNeeded("acre",
    (res) => {
      approveIfNeeded("water",
        (res) => {
          setIsPending(true)
          setPendingText("Minting a Plantation")
          farms.methods.createPlantation()
            .send({from: window.ethereum.selectedAddress})
            .then((res) => {
              getUserBalance()
              getAcreBalance()
              getWaterBalance()
              getAllGlobalEmissions()
              setViewFarms(false)
              setTimeout(()=>{setViewFarms(true)}, 400)
              setIsPending(false)
            }
              
              )
        }
        )
    }
    )
}

const approveThenMintGseedFarm = () => {
  approveIfNeeded("acre",
    (res) => {
      approveIfNeeded("water",
        (res) => {
          setIsPending(true)
          setPendingText("Minting a gSeed Farm")
          farms.methods.createGseedFarm()
            .send({from: window.ethereum.selectedAddress})
            .then((res) => {
              getUserBalance()
              getAcreBalance()
              getWaterBalance()
              getAllGlobalEmissions()
              setViewFarms(false)
              setTimeout(()=>{setViewFarms(true)}, 400)
              setIsPending(false)
            }
              
              )
        }
        )
    }
    )
}

const [acreBal, setAcreBal] = React.useState(0)
const getAcreBalance = () => {
  let x = new web3.eth.Contract(contractConfigs["acre"]["abi"], contractConfigs["acre"]["address"])
  x.methods.balanceOf(window.ethereum.selectedAddress)
    .call({from: window.ethereum.selectedAddress})
    .then((res) => {
      setAcreBal(res)
    })
}

const [waterBal, setWaterBal] = React.useState(0)
const getWaterBalance = () => {
  let x = new web3.eth.Contract(contractConfigs["water"]["abi"], contractConfigs["water"]["address"])
  x.methods.balanceOf(window.ethereum.selectedAddress)
    .call({from: window.ethereum.selectedAddress})
    .then((res) => {
      setWaterBal(res)
    })
}

const [bushelBal, setBushelBal] = React.useState(0)
const getBushelBal = () => {
  let x = new web3.eth.Contract(contractConfigs["bushel"]["abi"], contractConfigs["bushel"]["address"])
  x.methods.balanceOf(window.ethereum.selectedAddress)
    .call({from: window.ethereum.selectedAddress})
    .then((res) => {
      setBushelBal(res)
    })
}

const [gseedBal, setGseedBal] = React.useState(0)
const getGseedBal = () => {
  let x = new web3.eth.Contract(contractConfigs["gseed"]["abi"], contractConfigs["gseed"]["address"])
  x.methods.balanceOf(window.ethereum.selectedAddress)
    .call({from: window.ethereum.selectedAddress})
    .then((res) => {
      setGseedBal(res)
    })
}

const [caneBal, setCaneBal] = React.useState(0)
const getCaneBal = () => {
  let x = new web3.eth.Contract(contractConfigs["cane"]["abi"], contractConfigs["cane"]["address"])
  x.methods.balanceOf(window.ethereum.selectedAddress)
    .call({from: window.ethereum.selectedAddress})
    .then((res) => {
      setCaneBal(res)
    })
}


const [viewFarms, setViewFarms] = React.useState(false)


const [pendingText, setPendingText] = React.useState("")
const [showMinters, setShowMinters] = React.useState(false)


const [bushelEmissions, setBushelEmissions] = React.useState(0)
const [caneEmissions, setCaneEmissions] = React.useState(0)
const [gseedEmissions, setGseedEmissions] = React.useState(0)

const getAllGlobalEmissions = () => {
  farms.methods.getBushelEmissions()
    .call({from: window.ethereum.selectedAddress})
    .then(
      (res) => {
        setBushelEmissions(res)

      })
    farms.methods.getCaneEmissions()
    .call({from: window.ethereum.selectedAddress})
    .then(
      (res) => {
        setCaneEmissions(res)
        
      })
    farms.methods.getGseedEmissions()
    .call({from: window.ethereum.selectedAddress})
    .then(
      (res) => {
        setGseedEmissions(res)
        
      })
}
const [showWallet, setShowWallet] = React.useState(false);
const [showEmissions, setShowEmissions] = React.useState(false);
const [showBurnStats, setShowBurnStats] = React.useState(false);
const [totalSupply, setTotalSupply] = React.useState(false);
const getTotalSupply = () => {
  farms.methods.totalSupply()
    .call({from: window.ethereum.selectedAddress})
    .then(
      (res) => {
        setTotalSupply(res)
      })
}
  return (
    <div className={"App-wrapper " + " connected--" + connected}>
     <div className="bg"><img src={bg} /></div>
      <div>  
          <div className={"farms farms--" + viewFarms}>
            <Farm img={activeImg} tid={activeFarm}/>
            <div className="farmnav">
            <button className="btn" onClick={prev}>Previous</button>
            <span>{activeIndex}</span>
            <button className="btn" onClick={next}>Next</button>
            </div>
          </div>    
      </div>
      <div className={"mint-btns mint-btns--" + showMinters}>
          <span>Cost: 100 Acres & 50 Water</span>
          <button 
            onClick={() => {
              approveThenMintGseedFarm() }}>
              Mint New Gseed Farm</button>
          <button 
            onClick={() => {
              approveThenMintWheatFarm() }}>
              Mint New Wheat Farm</button>
          <button 
            onClick={() => {
              approveThenMintPlantation() }}>
              Mint New Plantation</button>
      </div>
      <div className={
        "balances balances--burns balances--" + showBurnStats}>
        <span>{formatTokenBalance(0,totalSupply)} gFARM</span>
        <span>{formatTokenBalance(0,totalSupply*100)} Acres Burned</span>
        <span>{formatTokenBalance(0,totalSupply*50)} gH20 Burned</span>
        <span className="click-span" onClick={
          ()=>{
            (showBurnStats) ? setShowBurnStats(false) : setShowBurnStats(true)
          }}>burns stats</span>
        </div>    
      <div className={
        "balances balances--emissions balances--" + showEmissions}>
        <span>{formatTokenBalance(18,bushelEmissions)} Bushels per Day</span>
        <span>{formatTokenBalance(18,caneEmissions)} Cane per Day</span>
        <span>{formatTokenBalance(18,gseedEmissions)} gSeed per Day</span>
        <span className="click-span" onClick={
          ()=>{
            (showEmissions) ? setShowEmissions(false) : setShowEmissions(true)
          }}>emissions</span>
      </div>
      <div className={"balances balances--" + showWallet}>
        {//<h3>Your Balances</h3>
        }
        <span>{formatTokenBalance(18,acreBal)} Acres</span>
         <span>{formatTokenBalance(18,waterBal)} gH20</span>
         <span>{formatTokenBalance(18,bushelBal)} BUSHEL</span>
         <span>{formatTokenBalance(18,caneBal)} CANE</span>
         <span>{formatTokenBalance(18,gseedBal)} gSEED</span>
         <span className="click-span" onClick={
          ()=>{
            (showWallet) ? setShowWallet(false) : setShowWallet(true)
          }}>wallet</span>
      </div>
      <div className="header"><h2>Deliland Farms: You have {userBal} Farms</h2></div>
      <div className={"pender isPending--" + isPending}>{pendingText}</div>
      <button disabled={isDisabled} onClick={connectMM} className="mmbtn">
        {mmBtnText}
      </button>
       <div className="control-btns">
          <button  onClick={() => {
          (viewFarms) ? setViewFarms(false) : setViewFarms(true) }}>View Farms</button>
          </div>
          <button className="toggle-minters" onClick={()=>{(showMinters) ? setShowMinters(false) : setShowMinters(true)}}>Toggle Minting Panel</button>
      <Footer />
    </div>

  );
}

